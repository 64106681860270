import { navigate } from 'gatsby';
import { useEffect } from 'react';
// import React from 'react';
// import Radio from 'pages/Radio';

const RadioPage = () => {
  useEffect(() => {
    navigate('/404');
  }, []);

  return null;
  //   return <Radio />;
};

export default RadioPage;
